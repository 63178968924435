import React from "react";
import {Card, Table} from "react-bootstrap";
import util from "../../../utils/util";
import 'moment/locale/nl-be';
import RegistratieBadge from "../../components/RegistratieBadge";
import {EqDay, EqMonth, EqYear, getFullWeeksInMonth} from "../calendar-util";
import {CalendarEntry} from "../../../../libs/api/time/api-model";
import * as A from 'fp-ts/array'
import * as O from 'fp-ts/option'
import {pipe} from "fp-ts/function";
import { getISOWeek, format, parse } from 'date-fns';
import classNames from "classnames";

type CalendarMonthProps = {
  date: Date,
  data: CalendarEntry[],
  onEdit: (date: Date) => void,
}

const CalendarMonth: React.FC<CalendarMonthProps> = (
  { date, data, onEdit }
) => {
  const weeksInMonth = getFullWeeksInMonth(date.getFullYear(), date.getMonth() + 1);

  const findEntry: (day: Date) => O.Option<CalendarEntry> = (day: Date) => pipe(
    day,
    O.fromNullable,
    O.chain(d =>
      pipe(data,
        A.findFirst(item =>
          EqDay.equals(d, parse(item.date, 'yyyy-MM-dd', new Date()))
        )
      )
    )
  );

  return (
    <Card className="flex-fill w-100 h-100">
      <Card.Header>
        <Card.Title className="mb-0">
          <h5>{ util.isDefined(date) ? format(date, 'MMMM') : 'selecteer datum' }</h5>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table size="sm" className="mt-0 mb-4">
          <thead>
          <tr>
            <td style={{ width: '9%' }}>&nbsp;</td>
            {
              pipe(
                weeksInMonth,
                A.head,
                O.map(firstWeek =>
                  <>
                    {
                      pipe(firstWeek,
                        A.map(day =>
                          <th key={`dayheader_${format(day, 'dd')}`}
                              style={{width: '13%'}}
                              className="text-center">
                            { format(day, 'dd') }
                          </th>
                        )
                      )
                    }
                  </>
                ),
                O.getOrElse(() => <></>)
              )
            }
          </tr>
          </thead>
          <tbody>
          {
            pipe(
              weeksInMonth,
              A.map(week =>
                <tr key={`weekrow_${getISOWeek(week[0])}`}>
                  <th key={`weekheader_${getISOWeek(week[0])}`} style={{width: '9%'}}>{ getISOWeek(week[0]) }</th>
                  {
                  pipe(week,
                    A.map(day => (
                      <td key={`daycolumn_${format(day, 'dd')}`}
                          style={{ width: '13%', height: '85px' }}
                          className={classNames(
                            'text-center',
                            EqMonth.equals(day, date) ? '' : 'table-light text-muted',
                            EqYear.equals(day, date) ? 'hoverable' : ''
                          )}
                          onClick={() => onEdit(day)}>
                        <>
                        <small>{ format(day, 'dd') }</small>
                        {
                          pipe(findEntry(day),
                            O.map(entry =>
                              <><br/><h3><RegistratieBadge as={'span'} className='mt-2' registratie={ entry.registratie } /></h3></>
                            ),
                            O.getOrElse(() => <></>)
                          )
                        }
                        </>
                      </td>
                    ))
                  )
                  }
                </tr>
              )
            )
          }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default CalendarMonth;
