import {Link} from "react-router-dom";
import SelectMultiColumnFilter from "../components/tables/filters/SelectMultiColumnFilter";
import {isActive} from "../../model/api-model-operators";
import BooleanColumnFilter from "../components/tables/filters/BooleanColumnFilter";
import {CheckCircle, XCircle} from "react-feather";
import * as React from "react";

const VehicleLink = ({value}) =>
  <Link to={`/pages/material/vehicle/${value}`}>
    {value}
  </Link>

function multiSelectFilter(rows, columnIds, filterValue) {
  const selected = filterValue.map((option) => option.value);
  return selected.length === 0
      ? rows
      : rows.filter((row) => selected.includes(String(row.values[columnIds])));
}
const VehicleActiveState = ({value}) =>
    <span>
    {value ?
        <CheckCircle size={18} className="feather align-middle text-success" /> :
        <XCircle size={18} className="feather align-middle text-danger" />}
    </span>;

const vehicleColumns = [
  {
    Header: "Nummerplaat",
    accessor: "licensePlate",
    disableSortBy: true,
    Cell: VehicleLink
  },
  {
    Header: "Merk/Model",
    disableSortBy: true,
    accessor: "name",
  },
  {
    Header: "Bestuurder",
    disableSortBy: true,
    accessor: "driver.name",
  },
  {
    Header: "Site",
    disableSortBy: true,
    accessor: "site.name",
    Filter: SelectMultiColumnFilter,
    filter: multiSelectFilter,
  },
  {
    Header: "Type",
    disableSortBy: true,
    accessor: "type.name",
    Filter: SelectMultiColumnFilter,
    filter: multiSelectFilter,
  },
  {
    Header: "Actief",
    id: 'active',
    accessor: (row) => isActive(row),
    width: "5%",
    Cell: VehicleActiveState,
    Filter: BooleanColumnFilter,
    disableSortBy: true,
    className: 'align-top text-center'
  },
];

export { vehicleColumns };
