import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet-async";
import {Button, Card, Col, Container, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import {AlertCircle, CheckCircle, Frown, PlusCircle, RefreshCw} from "react-feather";
import {postCalculationColumns, renderTimesheetDescription} from "./columns";
import TableWithSubComponents from "../components/TableWithSubComponents";
import moment from "moment";
import ConfirmationDialog from "../components/ConfirmationDialog";
import {useNavigate, useParams} from "react-router-dom";
import {Moment} from "moment/moment";
import * as O from "fp-ts/Option";
import {
  EmployeeSummary,
  TimesheetEntry
} from "../../../libs/api/time/api-model";
import {none, Option, some} from "fp-ts/Option";
import {mapService, Service} from "../../services/Service";
import {pipe} from "fp-ts/function";
import util, {parseNumber} from "../../utils/util";
import {Employees, Timesheets} from "../../services/TimeApi";
import ErrorHandlingTS from "../components/ErrorHandlingTS";
import SelectEmployee from "../components/form/SelectEmployee";
import DatePickerWithNavigation from "../components/DatePickerWithNavigation";
import {contramap, Eq} from "fp-ts/Eq";
import * as N from "fp-ts/number";
import {FormikHelpers} from "formik/dist/types";
import formikHandler from "../../services/FormikApiHandler";
import EditPostCalculationControl from "./PostCalculationControl";

const dateFormat = 'yyyy-MM-DD';
const EqById: Eq<EmployeeSummary> = pipe(N.Eq, contramap((o) => o.id));

const parseDate = (dateAsString: string | undefined) => {
  const parsed = util.isDefined(dateAsString) && moment(dateAsString);
  return moment.isMoment(parsed) ? parsed : moment();
};

const baseUrl = '/pages/registrations/postcalc/daily'

const generatedUrl = (date: Moment, employee: O.Option<EmployeeSummary>) =>
  pipe(employee,
    O.map(e => `${baseUrl}/${date.format(dateFormat)}/employee/${e.id}`),
    O.getOrElse(() => `${baseUrl}/${date.format(dateFormat)}`)
  );

const PostCalculation: React.FC = () => {

  const { date: dateUrlParam, employee: employeeUrlParam} = useParams();
  const navigate = useNavigate();

  const [date, setDate] = useState<Moment>(parseDate(dateUrlParam));
  const [employee, setEmployee] = useState<O.Option<EmployeeSummary>>(O.none);

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [editInstance, setEditInstance] = useState<Option<TimesheetEntry>>(none);

  const [timesheets, setTimesheets] = useState<Service<TimesheetEntry[]>>({ status: 'init' });
  const [deleteState, setDeleteState] = useState<Service<string>>({ status: 'init' });
  const [gdReloadState, setGdReloadState] = useState<Service<string>>({ status: 'init' });

  const parseEmployeeUrlParameter = () => pipe(
    O.fromNullable(employeeUrlParam),
    O.map((p) => parseNumber(p)),
    //O.filter(id => pipe(employee, O.map(e => e.id), O.elem(N.Eq)(id))),
    O.map((p) => Employees.summary(p)((response) => {
      mapService(setEmployee)(mapService(O.some)(response));
    })),
    O.toUndefined
  );

  const loadReport = () => pipe(
    employee,
    O.fold(
      () => setTimesheets({ status: 'init' }),
      (e) => Timesheets.daily(e.employeeNr, date)(setTimesheets)
    )
  );

  useEffect(parseEmployeeUrlParameter, [ employeeUrlParam ]);
  useEffect(loadReport, [ date, employee ]);

  const dateSelected = (value: Moment | string) => {
    if (typeof value === 'string') {
      console.error(`received invalid date ${value}`)
    } else if (date.format(dateFormat) !== value.format(dateFormat)) {
      setDate(value);
      navigate(generatedUrl(value, employee));
    }
  };

  const employeeSelected = (value?: EmployeeSummary): void => {
    pipe(
      value,
      O.fromNullable,
      O.filter(newEmployee =>
        !pipe(employee, O.elem(EqById)(newEmployee))
      ),
      O.map(newEmployee =>
        navigate(generatedUrl(date, O.some(newEmployee)))
      )
    );
  };

  const confirmEdit = (entity: TimesheetEntry) => {
    setEditInstance(some(entity));
    setShowEditModal(true);
  };

  const confirmDelete = (entity: TimesheetEntry) => {
    setEditInstance(some(entity));
    setShowDeleteModal(true);
  };

  const createEntity = (item: TimesheetEntry, helpers: FormikHelpers<TimesheetEntry>) => {
    const createItem: TimesheetEntry = {
      "projectGroupId": item.projectRef.groupId,
      "activity": item.activityRef.id,
      ...item
    };
    Timesheets.create(createItem)(response => {
      formikHandler(helpers)(response);
      if (response.status === 'loaded') {
        setShowCreateModal(false);
        loadReport();
      }
    })
  };

  const updateEntity = (item: TimesheetEntry, helpers: FormikHelpers<TimesheetEntry>) =>
    Timesheets.update(item.id, item)(response => {
      formikHandler(helpers)(response);
      if (response.status === 'loaded') {
        setShowEditModal(false);
        setEditInstance(none);
        loadReport();
      }
    });

  const deleteEntity = () => pipe(editInstance,
    O.map(item =>
      Timesheets.delete(item.id)(response => {
        setDeleteState(response);
        if (response.status === 'loaded') {
          setShowDeleteModal(false);
          setEditInstance(none);
          loadReport();
        }
      })
    )
  );

  const reloadGD = () => pipe(employee,
    O.map(e => {
      setGdReloadState({ status: 'loading'} );
      return Timesheets.syncGeoDynamics(e.employeeNr, date)(response => {
        setGdReloadState(response);
        if (response.status === 'loaded') {
          loadReport();
        }
      });
    })
  );

  const durationTotal = (entities: string[]): string => {
    const total = entities.map((element) => moment.duration(element)).reduce((acc, a) => acc.add(a), moment.duration(0));
    return moment.utc(total.as('milliseconds')).format('HH:mm');
  }

  const totalDuration = (entities: TimesheetEntry[]): string => durationTotal(entities.map(({duration}) => duration));
  const totalPause = (entities: TimesheetEntry[]): string => durationTotal(entities.map(({pause}) => pause));
  const totalKilometers = (entities: TimesheetEntry[]): number => entities.map(({km}) => km).reduce((acc, a) => acc + a, 0);

  const GDButton: React.FC = () => {
    switch (gdReloadState.status) {
      case 'init':
      case 'loaded':
        return <><RefreshCw className="feather me-1" />Ophalen uit GD</>;
      case 'loading':
        return <><Spinner className="feather me-1" size="sm" animation={"grow"} />Ophalen uit GD</>;
      case "error":
        return <><AlertCircle className="feather me-1" />Ophalen uit GD</>;
    }
  }

  return(
    <>
      <Helmet title="Project Registraties" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Project Registraties</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <SelectEmployee
                  idType="id"
                  onChange={ employeeSelected }
                  value={ pipe(employee, O.map(e => e.id?.toString()), O.toUndefined) }
                  usePaging={true} />
              </Col>
              <Col md={3}>
                <DatePickerWithNavigation
                  input={true}
                  value={ date }
                  initialValue={moment()}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={ dateSelected }
                  closeOnSelect={true}
                  locale="nl-be"
                  inputProps={{
                    className: "form-control text-center",
                  }}
                />
              </Col>
              <Col md={5} className='ms-auto text-end'>
                {
                  (gdReloadState.status === 'loaded') && <CheckCircle className="me-1 feather text-success"/>
                }
                <OverlayTrigger overlay={<Tooltip>Synchroniseren met Geodynamics</Tooltip>}>
                  <Button size={"lg"} variant="outline-warning" className="shadow-sm me-1"
                          onClick={() => reloadGD()}
                          disabled={ O.isNone(employee) }>
                    <GDButton />
                  </Button>
                </OverlayTrigger>
                <Button size={"lg"} variant="success" className="shadow-sm me-1"
                        onClick={() => setShowCreateModal(true)}
                        disabled={ O.isNone(employee) }>
                  <PlusCircle className="feather me-1" />Toevoegen
                </Button>
                {(gdReloadState.status === 'error') && <div className="text-danger">
                    <Frown/> Fout bij het ophalen van de data {gdReloadState.error?.message ?? ''}
                </div>
                }
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ErrorHandlingTS service={timesheets} onLoaded={(report) =>
          <Card>
            <TableWithSubComponents bordered
                                    data={report}
                                    columns={postCalculationColumns(confirmEdit, confirmDelete)}
                                    renderRowSubComponent={renderTimesheetDescription}
                                    hiddenColumns={['description']}
                                    renderFooter={(rows, visibleColumnsLength) =>
                                      <tr>
                                        <th scope="row" colSpan={4}>Totaal</th>
                                        <td className="text-center fw-bolder">{ totalPause(report) }</td>
                                        <td className="text-center fw-bolder">{ totalDuration(report) }</td>
                                        <td className="text-end fw-bolder">{ totalKilometers(report) }</td>
                                        <td colSpan={3}>&nbsp;</td>
                                      </tr>
                                    }
            />
          </Card>
        } />
      </Container>
      {
        pipe(editInstance, O.fold(
          () => <></>,
          (item) =>
            <EditPostCalculationControl modalOpen={showEditModal}
                                        onModalHide={() => setShowEditModal(false)}
                                        onSave={(item, helpers) => updateEntity(item, helpers)}
                                        item={item}
            />
        ))
      }
      { /* todo standaard activiteit van employee gebruiken bij aanmaken nieuw item */ }
      {
        pipe(employee, O.fold(
          () => <></>,
          (e) =>
            <EditPostCalculationControl modalOpen={showCreateModal}
                                        onModalHide={() => setShowCreateModal(false)}
                                        onSave={createEntity}
                                        item={{
                                          id: 0,
                                          date: date.format('yyyy-MM-DD'),
                                          employeeRef: e,
                                          employeeId: e.id,
                                          activity: e.activityId, //todo: hier standaard activiteit van employee nemen
                                          activityRef: null,
                                          projectRef: null,
                                          start: '',
                                          end: '',
                                          pause: '',
                                          duration: '',
                                          km: 0,
                                          driver: false,
                                          licensePlate: '',
                                          description: '',
                                        }}
            />
        ))
      }
      <ConfirmationDialog title={`Timesheet entry verwijderen`}
                          open={showDeleteModal}
                          loading={deleteState.status === 'loading'}
                          error={deleteState.status === 'error'}
                          onCancel={() => setShowDeleteModal(false)}
                          onConfirm={ deleteEntity }>
        <p>Ben je zeker dat je timesheet entry wil verwijderen?</p>
      </ConfirmationDialog>
    </>
  );
}

export default PostCalculation;
