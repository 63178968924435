import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";

const Blank: React.FC = () => (
  <>
    <Helmet title="Blank Page" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Blank Page</h1>

      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">
                <h5>Empty card</h5>
              </Card.Title>
            </Card.Header>
            <Card.Body>&nbsp;</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
);

export default Blank;
