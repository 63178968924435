import * as React from "react";
import {useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Service} from "../../../services/Service";
import {Helmet} from "react-helmet-async";
import {Card, Container, } from "react-bootstrap";
import Selectors from "./components/Selectors";
import TableWithFiltering from "../../components/TableWithFiltering";
import {initialPresenceSort, presenceColumns} from "./columns";
import ErrorHandlingTS from "../../components/ErrorHandlingTS";
import util from "../../../utils/util";
import moment from "moment";
import {Moment} from "moment/moment";
import {Timesheets} from "../../../services/DalServiceTS";
import {PrestatieControle} from "../../../../libs/api/dal/api-model";

const dateFormat = 'yyyy-MM-DD';

const parseDate = (dateAsString: string | undefined) => {
  const parsed = util.isDefined(dateAsString) && moment(dateAsString);
  return moment.isMoment(parsed) ? parsed : moment();
};

const rowClass = (row: any) =>
  //row.values.toPerform !== row.values.performed.performed ? 'table-warning' :
  util.isDefined(row.values.checkin) && util.isDefined(row.values.checkout) ? 'table-success' :
    util.isDefined(row.values.checkin) && !util.isDefined(row.values.checkout) ? 'table-primary' : '';

const DailyPresence: React.FC = () => {
  const { date: dateUrlParam } = useParams();
  const navigate = useNavigate();
  const [entriesData, setEntriesData] = useState<Service<PrestatieControle[]>>({ status: 'init' });
  const [date, setDate] = useState<Moment>(parseDate(dateUrlParam));

  const loadEntries = () => {
    setEntriesData({ status: 'loading' });
    Timesheets.get(date,
      (response: PrestatieControle[]) => setEntriesData({ status: 'loaded', payload: response }),
      (error: any) => setEntriesData({ status: 'error', error }),
    );
  };

  const dateSelected = (value: Moment | string) => {
    if (typeof value === 'string') {
      setEntriesData({ status: 'error', error: new Error(`invalid date selection: ${value}`)});
    } else if (date.format(dateFormat) !== value.format(dateFormat)) {
      navigate(`/pages/registrations/presence/daily/${value.format(dateFormat)}`);
    }
  };

  useEffect(loadEntries, [ date ]);
  useEffect(() => {
    if (dateUrlParam !== date.format(dateFormat)) {
      setDate(parseDate(dateUrlParam));
    }
  }, [date, dateUrlParam]);

  return (
    <>
      <Helmet title="Aanwezigheid" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Aanwezigheid</h1>
        <Selectors value={date} onChange={dateSelected} />
        <ErrorHandlingTS service={entriesData} onLoaded={ (entries) =>
          <Card>
            <TableWithFiltering bordered hover data={entries} columns={presenceColumns} getRowProps={row => ({
              className: rowClass(row),
            })} initialSort={initialPresenceSort} />
          </Card>
        } />
      </Container>
    </>
  );
}

export default DailyPresence;
