import React, { useEffect } from "react";

import {
  Card, Col,
  Container,
  Row,
} from "react-bootstrap";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../constants";

import useTheme from "../hooks/useTheme";
import useSidebar from "../hooks/useSidebar";
import useLayout from "../hooks/useLayout";

import {Helmet} from "react-helmet-async";

const Dashboard: React.FC = () => (
  <>
    <Helmet title="Blank Page" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Dashboard</h1>

      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">
                <h5>Under construction</h5>
              </Card.Title>
            </Card.Header>
            <Card.Body>&nbsp;</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
);


const Landing: React.FC = () => {
  const { setTheme } = useTheme();
  const { setPosition, setBehavior } = useSidebar();
  const { setLayout } = useLayout();

  useEffect(() => {
    setTheme(THEME.DEFAULT);
    setPosition(SIDEBAR_POSITION.LEFT);
    setBehavior(SIDEBAR_BEHAVIOR.STICKY);
    setLayout(LAYOUT.FLUID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dashboard />
    </>
  );
};

export default Landing;
