import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button } from "react-bootstrap";

const Page403: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet title="404 Error"/>
      <div className="text-center">
        <h1 className="display-1 fw-bold">403</h1>
        <p className="h1">Page not accessible.</p>
        <p className="h2 fw-normal mt-3 mb-4">
          Unauthorized. The page you are trying to access is restricted.
        </p>
        <Button variant="primary" size="lg" onClick={() => navigate(-2)}>
          Take me back
        </Button>
      </div>
    </>
  );
}

export default Page403
