import { pipe } from 'fp-ts/function';
import * as Eq from "fp-ts/Eq";
import * as A from 'fp-ts/array'
import * as N from 'fp-ts/number'

export const EqYear: Eq.Eq<Date> = pipe(N.Eq, Eq.contramap((date) => date.getFullYear()));
const EqMonthOnly: Eq.Eq<Date> = pipe(N.Eq, Eq.contramap((date) => date.getMonth()));
const EqDayOnly: Eq.Eq<Date> = pipe(N.Eq, Eq.contramap((date) => date.getDate()));

export const EqMonth: Eq.Eq<Date> = {
  equals: (x, y) => EqYear.equals(x, y) && EqMonthOnly.equals(x, y)
};

export const EqDay: Eq.Eq<Date> = {
  equals: (x, y) => EqMonth.equals(x, y) && EqDayOnly.equals(x, y)
};

export type Week = Date[];

/**
 * Get the Monday of the week for a given date.
 */
const getStartOfWeek = (date: Date): Date => {
  const day = date.getDay();
  const diff = day === 0 ? -6 : 1 - day; // Adjust Sunday (-6) and other days
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff);
};

/**
 * Generate an array of full weeks in a month (Monday-start weeks).
 */
export const getFullWeeksInMonth = (year: number, month: number): Week[] => {
  const firstDayOfMonth = new Date(year, month - 1, 1); // Month is 0-based
  const lastDayOfMonth = new Date(year, month, 0); // Last day of the month

  // Get the Monday before or on the first day of the month
  const start = getStartOfWeek(firstDayOfMonth);

  // Get the Sunday after or on the last day of the month
  const end = new Date(lastDayOfMonth);
  end.setDate(end.getDate() + (7 - end.getDay()) % 7); // Adjust to next Sunday

  // Generate an array of all dates between start and end
  const generateDates = (start: Date, end: Date): Date[] => {
    const result: Date[] = [];
    let current = new Date(start);

    while (current <= end) {
      result.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }

    return result;
  };

  // Split the array of dates into weeks
  const chunkIntoWeeks = (dates: Date[]): Week[] =>
    pipe(
      dates,
      A.chunksOf(7) // Group into arrays of 7 days (1 week)
    );

  // Combine the steps
  return pipe(
    generateDates(start, end), // Generate all dates
    chunkIntoWeeks // Split into weeks
  );
};

export const getMonthsInYear = (year: number = new Date().getFullYear()): Date[] =>
  pipe(
    Array.from({ length: 12 }, (_, month) => month), // Create an array of month indices (0 to 11)
    A.map((month) => new Date(year, month, 1)) // Map each month index to a Date object (1st of each month)
  );


