import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import logo from "../../assets/img/logo.svg";

const SignInPage: React.FC = () => (
  <>
    <Helmet title="Sign In" />
    <div className="text-center mt-4">
      <h2>Time Management</h2>
      <p className="lead">Gelieve in te loggen om verder te gaan</p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <div className="text-center">
            <img
              src={logo}
              alt="VE Time Module"
              className="img-fluid"
              width="132"
              height="132"
            />
          </div>
          <SignIn />
        </div>
      </Card.Body>
    </Card>
  </>
);

export default SignInPage;
